export default function AboutMe() {

    const onDownloadButtonClick = () => {
        fetch("Raimondas_CV.pdf").then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);

                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "Raimondas_CV.pdf";
                alink.click();
            })
        })
    }

    return(
        <section id="AboutMe" className="about--section">
            <div className="hero--section--content--box about--section--box">
                <div className="hero--section--content">
                    <p className="section--title">
                        About
                    </p>
                    <h1 className="skills--section--heading">About Me</h1>
                    <p className="hero--section-description">
                        I am requalifiying from mechanic
                        speciality to developer. I am
                        conscientious, hardworking,
                        always excited to learn new
                        things.
                        Interested in cars, technology,
                        riding a bicycle, hiking and being
                        in nature.
                    </p>
                </div>
                <div className="download--btn--section">
                    <button className="btn btn-primary contact--form-btn" onClick={onDownloadButtonClick}>Download CV</button>
                </div>
            </div>
        </section>
    )
};