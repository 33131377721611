import {useState} from "react";
import emailjs from '@emailjs/browser'

export default function ContactMe() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [stateMessage, setStateMessage] = useState(null)

    const sendEmail = (e) => {
        e.persist();
        e.preventDefault();
        setIsSubmitting(true);

        emailjs
            .sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                e.target,
                process.env.REACT_APP_PUBLIC_KEY,
            )
            .then(
                (result) => {
                    setStateMessage('Message sent!');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000)
                },
                (error) => {
                    setStateMessage('Something went wrong, please try again later');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null)
                    }, 5000)
        console.log(error)
                }
            );
    }

    return (
        <section className="contact--section" id="Contact">
            <div>
                <p className="sub--title">Get In Touch</p>
                <h2>Contact Me</h2>
                <p className="text-lg">If you have any interest to recruit me. Please write your message in the form below.</p>
            </div>
            <form className="contact--form--container" onSubmit={sendEmail} id='contactForm'>
                <div className="container">
                    <label htmlFor="first-name" className="contact--label">
                        <span className="text-md">Name</span>
                        <input type="text" className="contact--input text-md" name="first-name" id="first-name"
                               required/>
                    </label>
                    <label htmlFor="email" className="contact--label">
                        <span className="text-md">Email</span>
                        <input type="email" className="contact--input text-md" name="email" id="email"
                               required/>
                    </label>
                </div>
                <label htmlFor="message" className="contact--label">
                    <span className="text-md">Message</span>
                    <textarea className="contact--input text-md" name="message" id="message" rows="8" placeholder="Type your message..."></textarea>
                </label>
                <div>
                    {/*<button className="btn btn-primary contact--form-btn">Submit</button>*/}
                    <input className="btn btn-primary contact--form-btn" type="submit" value="Submit" disabled={isSubmitting}/>
                    {stateMessage && <p>{stateMessage}</p>}
                </div>
            </form>
        </section>
    )
};