import data from "../../data/index.json"

export default function Education() {
    return (
        <section className="work--section" id="education">
            <div className="portfolio--container-box">
                <div className="portfolio--container">
                    <h2 className="sections--heading">Education</h2>
                </div>
            </div>
            <div className="portfolio--section--container">
                {data?.education?.map((item, index) => (
                    <div key={index} className="work--section--card">
                        <div className="work--section--card--detail">
                            <div>
                                <p className="text-md company--name">{item.company_name}</p>
                                <p className="text-md work--designation">{item.speciality}</p>
                                <p className="text-md work--designation">{item.studies_length}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
};