import HeroSection from "../HeroSection";
import MySkills from "../MySkills";
import AboutMe from "../AboutMe";
import MyPortfolio from "../MyPortfolio";
import WorkExperience from "../WorkExperience";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import Education from "../Education";

export default function Home () {
  return (
      <>
        <HeroSection />
        <MySkills />
        <AboutMe />
        <MyPortfolio />
        <WorkExperience />
        <Education />
        <ContactMe />
        <Footer />
      </>
  );
};