import data from "../../data/index.json"

export default function WorkExperience() {
    return (
        <section className="work--section" id="work_experience">
            <div className="portfolio--container-box">
                <div className="portfolio--container">
                    <h2 className="sections--heading">Work Experience</h2>
                </div>
            </div>
            <div className="portfolio--section--container">
                {data?.jobs?.map((item, index) => (
                    <div key={index} className="work--section--card">
                        <div className="work--section--card--detail">
                            <img src={item.src} alt="Avatar" width="76" height='75'/>
                            <div>
                                <p className="text-md company--name">UAB "{item.company_name}"</p>
                                <p className="text-md work--designation">{item.job_position}</p>
                                <p className="text-md work--designation">{item.work_length}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
};